





















import { Component, Vue, Prop } from 'vue-property-decorator';
import { UnorderedList } from '@/types/cms/components';

/**
 * CMS unordered list component
 * 
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Component
export default class CMSComponentUnorderedList extends Vue {
    // Prop with list data
    @Prop({ type: Object as () => UnorderedList, required: true }) readonly listData: UnorderedList | undefined;
}
